export const ROUTE_MAP = {
    /** 商品详情 */
    PRODUCT_DETAIL: '/product/[name]/p/[csku]',
    /** 分类列表 */
    CATEGORY_LIST: '/category/[...category]/c/[id]',
    CATEGORY: '/category/[...path]',
    /** 首页 */
    HOME: '/',
    /** 结算页 */
    CHECKOUT: '/checkout',
    /** 购物车 */
    CART: '/cart',
    /** 搜索结果页 */
    SEARCH_RESULT: '/search',
    /** 搜索页 */
    SEARCH: '/search',
    /** 登录 */
    LOGIN: '/login',
    /** 注册 */
    REGISTER: '/register',
    /** 二次注册 */
    SECONDARY_REGISTER: '/register/secondary',
    /** 个人中心 */
    ACCOUNT: '/my-account',
    /** 订单记录 */
    ORDER_RECORD: '/my-account/order/[status]',
    /** 现金券 */
    CASH_COUPON: '/my-account/coupon',
    /** 订单详情 */
    ORDER_DETAIL: '/order-detail/[type]/[id]',
    /** 收貨地址 */
    DELIVERY: '/my-account/delivery',
    /** 异常页 */
    EXCEPTIONAL: '/exceptional',
    /** 注册成功 */
    REGISTER_SUCCESS: '/register/success',
    /** 登录成功 */
    LOGIN_SUCCESS: '/login/success',
    /** 忘记密码 */
    RESET_PASSWORD: '/reset-password',
    /** 重置密码成功 */
    RESET_PASSWORD_SUCCESS: '/reset-password/success',
    /** 支付状态页 */
    PAYMENT_STATUS: '/payment/status/[status]',
    TOPIC: '/campaign/[name]/[id]',
    /** 評價页 */
    EVALUATION: '/my-account/evaluation',
    /** 評價商品页 */
    EVALUATE_PRODUCT: '/my-account/evaluate-product/[id]',
    /** 售後列表页 */
    AFTER_SALES: '/my-account/after-sales/list',
    /** 編輯個人資料頁 */
    ACCOUNT_INFO: '/my-account/account-info',
    /** 积分详情页 */
    POINT_DETAIL: '/my-account/points',
    /** 收藏详情页 */
    COLLECT_DETAIL: '/my-account/collect',
    /** 申请会员 */
    APPLY_MEMBER: '/my-account/apply-member',
    /** 店铺资料 */
    STORE_INFORMATION: '/store-information',
    /** 申请售后 */
    AFTER_SALES_APPLY: '/my-account/after-sales/apply/[id]',
    /** 售后详情 */
    AFTER_SALES_DETAIL: '/my-account/after-sales/detail/[id]',
    /** 領券中心 */
    RECEIVE_COUPON: '/my-account/receive-coupon',
    /** 品牌PLP */
    BRANCH: '/brand/[name]/[brandId]',
    /** 促销PLP */
    PROMOTION: '/promotion/[name]/[promotionId]',
    /** 合作商户PLP */
    MERCHANT: '/merchant/[name]/[merchantId]',
    /** 消息通知 */
    MESSAGE: '/message',
    /** 消息通知 */
    MESSAGE_DETAIL: '/message/[id]',
    /** 产品对比 */
    PRODUCT_DIFF: '/product-diff/[csku]',
    /** 线下转账 */
    TRANSFER_MONEY: '/transfer-money',
    /** 短链跳转中转页 */
    DOWNLOAD_MIDDLE: '/app'
};
// router.push(createPath(ROUTE_MAP.CATEGORY_LIST,{category:['ss','sss'],id:'222'}))
/**
 * 路径名称映射表
 */
export const PATH_NAME_MAP = {
    [ROUTE_MAP.HOME]: 'homePage',
    [ROUTE_MAP.CATEGORY]: 'productListingPage',
    [ROUTE_MAP.BRANCH]: 'productListingPage',
    [ROUTE_MAP.MERCHANT]: 'productListingPage',
    [ROUTE_MAP.PROMOTION]: 'productListingPage',
    [ROUTE_MAP.PRODUCT_DETAIL]: 'productDetailsPage',
    [ROUTE_MAP.CART]: 'shoppingCart',
    [ROUTE_MAP.CHECKOUT]: 'checkoutPage',
    [ROUTE_MAP.LOGIN]: 'memberLogin',
    [ROUTE_MAP.REGISTER]: 'memberRegistration',
    [ROUTE_MAP.ACCOUNT]: 'myAccount',
    [ROUTE_MAP.ORDER_RECORD]: 'myAccount',
    [ROUTE_MAP.DELIVERY]: 'myAccount',
    [ROUTE_MAP.SEARCH_RESULT]: 'searchPage',
    [ROUTE_MAP.PRODUCT_DIFF]: 'productDiffPage',
    [ROUTE_MAP.TRANSFER_MONEY]: 'transferMoney',
    [ROUTE_MAP.TOPIC]: 'campaignPage'
};
export const NEED_LOGIN_PATH = ['/my-account/.*', '/checkout'];
/**
 * Checks if a given URL requires login.
 *
 * @param {string} url - The URL to check.
 * @return {boolean} Returns true if the URL requires login, otherwise false.
 */
export function checkUrlNeedLogin(url) {
    return NEED_LOGIN_PATH.some((pattern) => {
        const regex = new RegExp(`^${pattern.replace(/\//g, '/').replace(/\[.*\]/g, '.*')}$`);
        return regex.test(url.replace(/^\/(tc|cn|en)\/?/, '/'));
    });
}
