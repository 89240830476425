var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-case-declarations */
import { CartApi } from '@common/api/cart';
import { ProductListApi } from '@common/api/product-list';
import { createPath } from '@common/application/utils/create-path';
import { emitUpdateCartNumber } from '@common/application/utils/event';
import { isGuest } from '@common/application/utils/is-guest';
import { Toast } from '@common/application/utils/ui/toast';
import { API_LANGUAGE } from '@common/constants/language';
import { CATEGORY_PARAMS } from '@common/constants/product-list';
import { ROUTE_MAP } from '@common/constants/route-map';
import { get } from 'lodash-es';
import Router from 'next/router';
import { i18n } from 'next-i18next';
import { emitCartNumber } from '../../cart-number';
const open = (path) => {
    Router.push(path);
};
export const common = {
    requests: {
        /**
         * 添加购物车
         */
        addCart(requestData) {
            var _a, _b;
            return __awaiter(this, void 0, void 0, function* () {
                const res = yield CartApi.add({
                    csku: requestData.csku,
                    rsku: requestData.rsku,
                    number: requestData.count,
                    goodsSpec: (_a = requestData.goodsSpec) !== null && _a !== void 0 ? _a : undefined,
                    promotionId: (_b = requestData.promotionId) !== null && _b !== void 0 ? _b : undefined
                });
                if (!res) {
                    Toast(i18n === null || i18n === void 0 ? void 0 : i18n.t('product-list:addCart'), { type: 'success' });
                    emitUpdateCartNumber();
                    emitCartNumber();
                }
            });
        },
        /**
         * 收藏商品
         */
        collect(requestData) {
            return __awaiter(this, void 0, void 0, function* () {
                if (!isGuest()) {
                    if (!requestData.isFavorite) {
                        const res = yield ProductListApi.collectionFavorite({
                            sku: requestData.csku
                        });
                        Toast(i18n === null || i18n === void 0 ? void 0 : i18n.t('product-list:successfulCollection'), { type: 'success' });
                        return new Promise((resolve) => resolve(Boolean(!res)));
                    }
                    const res = yield ProductListApi.delCollectionFavorite({
                        sku: requestData.csku
                    });
                    Toast(i18n === null || i18n === void 0 ? void 0 : i18n.t('product-list:uncollect'));
                    return new Promise((resolve) => resolve(Boolean(!res)));
                }
                Toast(i18n === null || i18n === void 0 ? void 0 : i18n.t('common:loginPlease'));
                return new Promise((resolve) => resolve(false));
            });
        }
    },
    events: {
        /**
         * linkType 跳转
         *
         * @param target 链接配置
         */
        onLink(target = {
            linkType: 'none'
        }) {
            const { linkType = 'none', linkParams } = target;
            const local = API_LANGUAGE[i18n === null || i18n === void 0 ? void 0 : i18n.language] || 'tc';
            let name = '';
            switch (linkType) {
                case 'none':
                    break;
                case 'product':
                    open(createPath(ROUTE_MAP.PRODUCT_DETAIL, {
                        name: linkParams.name || linkParams.cskuName,
                        csku: linkParams.csku
                    }, { withLocale: true }));
                    break;
                case 'home':
                    open('/');
                    break;
                case 'topic':
                case 'disableShareTopic':
                    if (!(linkParams === null || linkParams === void 0 ? void 0 : linkParams.id))
                        break;
                    open(createPath(ROUTE_MAP.TOPIC, { id: linkParams.id, name: linkParams.name }, { withLocale: true }));
                    break;
                case 'extraLink':
                    window.open(linkParams.extraLink);
                    break;
                case 'displayCategory':
                    if (!(linkParams === null || linkParams === void 0 ? void 0 : linkParams.id))
                        break;
                    name = get(linkParams, `nameMap.${local}`) || linkParams.name;
                    open(createPath(ROUTE_MAP.CATEGORY_LIST, {
                        category: [name],
                        id: linkParams.id,
                        type: CATEGORY_PARAMS.DISPLAY_CATEGORY
                    }, {
                        withLocale: true
                    }));
                    break;
                case 'live':
                    open('/live');
                    break;
                case 'virtualCategory':
                    if (!(linkParams === null || linkParams === void 0 ? void 0 : linkParams.id))
                        break;
                    name = get(linkParams, `languageName.${local}`) || linkParams.name;
                    open(createPath(ROUTE_MAP.CATEGORY_LIST, {
                        category: [name],
                        id: linkParams.id,
                        type: CATEGORY_PARAMS.VIRTUAL_CATEGORY
                    }, {
                        withLocale: true
                    }));
                    break;
                case 'otherMP':
                    break;
                case 'bp':
                    open('/bp');
                    break;
                case 'brands':
                    if (!(linkParams === null || linkParams === void 0 ? void 0 : linkParams.id))
                        break;
                    name = get(linkParams, `languageName.${local}`) || linkParams.name;
                    open(createPath(ROUTE_MAP.BRANCH, { brandId: linkParams.id, name }, {
                        withLocale: true
                    }));
                    break;
                case 'promotion':
                    open(createPath(ROUTE_MAP.PROMOTION, { promotionId: linkParams[0].id, name: linkParams[0].name }, { withLocale: true }));
                    break;
                default:
            }
        }
    }
};
/**
 * 用于注入到cms中的方法
 *
 * @param target 链接配置
 */
export function designOnLink(target = {
    linkType: 'none'
}) {
    const { linkType = 'none', linkParams } = target;
    const local = API_LANGUAGE[i18n === null || i18n === void 0 ? void 0 : i18n.language] || 'tc';
    let name = '';
    let path = '';
    switch (linkType) {
        case 'none':
            break;
        case 'product':
            path = createPath(ROUTE_MAP.PRODUCT_DETAIL, {
                name: linkParams.name || linkParams.cskuName,
                csku: linkParams.csku
            }, { withLocale: true });
            return path;
        case 'home':
            path = '/';
            return path;
        case 'topic':
        case 'disableShareTopic':
            if (!(linkParams === null || linkParams === void 0 ? void 0 : linkParams.id))
                break;
            path = createPath(ROUTE_MAP.TOPIC, { id: linkParams.id, name: linkParams.name }, { withLocale: true });
            return path;
        case 'extraLink':
            path = linkParams.extraLink;
            return path;
        case 'displayCategory':
            if (!(linkParams === null || linkParams === void 0 ? void 0 : linkParams.id))
                break;
            name = get(linkParams, `nameMap.${local}`) || linkParams.name;
            path = createPath(ROUTE_MAP.CATEGORY_LIST, {
                category: [name],
                id: linkParams.id,
                type: CATEGORY_PARAMS.DISPLAY_CATEGORY
            }, {
                withLocale: true
            });
            return path;
        case 'live':
            path = '/live';
            return path;
        case 'virtualCategory':
            if (!(linkParams === null || linkParams === void 0 ? void 0 : linkParams.id))
                break;
            name = get(linkParams, `languageName.${local}`) || linkParams.name;
            path = createPath(ROUTE_MAP.CATEGORY_LIST, {
                category: [name],
                id: linkParams.id,
                type: CATEGORY_PARAMS.VIRTUAL_CATEGORY
            }, {
                withLocale: true
            });
            return path;
        case 'otherMP':
            break;
        case 'bp':
            path = '/bp';
            return path;
        case 'brands':
            if (!(linkParams === null || linkParams === void 0 ? void 0 : linkParams.id))
                break;
            name = get(linkParams, `languageName.${local}`) || linkParams.name;
            path = createPath(ROUTE_MAP.BRANCH, { brandId: linkParams.id, name }, {
                withLocale: true
            });
            return path;
        case 'promotion':
            path = createPath(ROUTE_MAP.PROMOTION, { promotionId: linkParams[0].id, name: linkParams[0].name }, { withLocale: true });
            return path;
        default:
    }
}
